<template>
  <div class="content"> 
      <div v-html="vhtml" class="form"></div>

  </div>
</template>

<script>
export default {
    data() {
        return {
            data: '',
        }
    },
    props: ['vhtml']
}
</script>

<style  lang="less" scoped>
// @import url('https://fonts.googleapis.com/css?family=Lato:400,700|Lora|Playfair+Display:700i,900');
// @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

html,
body {
  margin: 0;
  width: 100%;
  background: #fafafa;
}

.form /deep/ img {
  display: inline;
  height: auto !important;
  max-width: 100% !important;
  max-height: 60% !important;
  margin-bottom: 10px;
}

.form /deep/ figure {
  display: inline;
  height: auto !important;
  max-width: 100% !important;
  max-height: 60% !important;
  margin-left: 0px;
  margin-right: 0px;
}

.form /deep/ iframe {
  position: relative;
  max-width: 100% !important;
  margin-top: 10px;
  margin-bottom: 10px;
  frameBorder: 0px;
}

.form /deep/ iframe.instagram-media {
  max-width: 98% !important;
  margin: auto auto 12px !important;
  min-width: 0px !important;
}

.form /deep/ div {
  max-width: 100% !important;
}

.form /deep/ video {
  max-width: 100% !important;
}

.form /deep/  h1,
h2,
p,
i,
a,
.first-letter,
.authorName a {
  text-rendering: optimizeLegibility;
}

.form /deep/ h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 38px;
  text-align: left;
  margin-bottom: 8px;
}

.form /deep/ h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 21px;
  font-weight: 700;
  padding:  56px 0 -13px -1.883px;
  padding: 56px 0 -13px -1.883px;
  text-align: left;
  line-height: 34.5px;
  letter-spacing: -0.45px;
}

.form /deep/ p, i, a {
  margin-top: 21px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  letter-spacing: -0.03px;
  line-height: 1.58;
}

.form /deep/ a {
  color: rgba(0, 0, 0, 0.84);
  text-decoration: none;
}



.form /deep/ code {
  font-size: 16px;
  background: rgba(0,0,0,.05);
  border-radius: 2px;
  padding: 3px 5px;
}

.form /deep/ mark, .highlighted {
  background: #7DFFB3;
}

.form /deep/ .first-letter {
  overflow-wrap: break-word;
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  line-height: 60px;
  display: block;
  position: relative;
  float: left;
  margin: 0px 7px 0 -5px;
}

.form /deep/ .subtitle {
  font-family: 'Roboto', sans-serif;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 24px 0;
}

.form /deep/ ::selection{background-color: lavender}

.form html ul li, body ul li {
  margin: 0;
}


// .form {
//     background : linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))
// }

.content {
  font-size: 0.5rem;

}
.content /deep/ .form {
  background : linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))
}
</style>
