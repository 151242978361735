<template>
  <div>

    <div id="taboola_detailpage_below_article_thumbnails_top" style="padding:0 10px;box-sizing:border-box" @click.stop="refresh_time"></div>
    <div
      class="title"
      style="margin-bottom: 10px; padding: 0 10px; box-sizing: border-box"
    >
      {{ title }}
    </div>
    <div style="font-size: 14px; margin-left: 10px; color: gray">
      {{ author }}
    </div>
    <!-- <div :style="{  maxHeight: clientHeight + 'px' }" style="overflow:hidden ;  position :relative">
      <Form :vhtml='v_html' style="padding: 10px; box-sizing: border-box ;" ></Form>
      <div style="position:absolute;background-image : linear-gradient(180deg ,  transparent 0% ,  transparent 65%, #f8f8f8 100%  ); left:0;right:0;bottom:0;top:0"></div>
    </div> -->
    <Form :vhtml='v_html' style="padding: 10px; box-sizing: border-box ;" ></Form>

    <div style="text-align:center;margin-top:20px;margin-bottom:10px"> 
      <van-button type="info" size="large" round style="width:90%;background-color: #685FF5;border: none;" @click="readMore" v-if="v_html">READ MORE</van-button>
    </div>

    <div id="taboola_detailpage_below_article_thumbnails" style="padding:0 10px;box-sizing:border-box" @click.stop="refresh_time"></div>


    <div style="margin-top:10px">
      <div style="min-height:0;color:gray;padding:10px 0" class="list-item">More News</div>
      <van-row class="list-item" v-for="(item,idx) in newsList" :key="idx"  @click="newsLink(item, item._id, 1)">
        <van-col :span="item.images[0] ? 15 : 23">
          <span class="van-multi-ellipsis--l3 p" style="font-size: .4rem;line-height: .6rem;">{{item.title}}</span>
          <!-- <span class="p" style="display:inline-block;margin-top: .5rem;font-size: .5333rem;color:#9E9E9E">{{item.channel_name}} </span> -->
        </van-col>
        <van-col span="8" offset="1" v-if="item.images[0]">
          <img style="width:100%;height:2.5rem;object-fit: cover;border-radius: 10px" :src="item.images[0]" alt="">
        </van-col>
      </van-row>
    </div>


    <van-popup v-model="taboola_popup_show" style="width:80%;padding:10px;box-sizing:border-box;"  :close-on-click-overlay="false" :duration="0" class="pop_cont">
      <div style="text-align:right;">
        <van-icon name="close" style="font-size: 25px;"  @click=" popup_close_click "/>
      </div>
      <div id="taboola_detailpage_below_article_thumbnails_popup" v-if="taboola_popup_show" @click.stop=" popup_close_click "></div>
    </van-popup>


  </div>
</template>

<script>
import Vue from "vue";
import { Image as VanImage , Tag} from 'vant';
import { Button } from 'vant';
Vue.use(Button);
import Form from './Form';


Vue.use(VanImage).use(Tag)
export default {
  data() {
    return {
      v_html: "",
      title: "",
      author: "",
      placements_res : [] ,
      _id : "" ,
      clientHeight : 0 , 
      link : "" ,
      time: parseInt(new Date().getTime() / 1000 / 1800 ), 
      news: {
        offset: 4,
        limit: 10
      },
      newsList : [] , 
      _type : "" , 
      interval : undefined , //定时器变量 
      taboola_popup_show : false , //taboola弹窗广告 
      session: 'init' ,

    };
  },
  components: {
      Form
  },
  mounted() {
    this.newsList = []
    this._id = this.$route.query.id;
    this._type = this.$route.query.type || 'headline' ;
    this.getDetail();
    window.scrollTo(0 , 0 )
    this.clientHeight = document.documentElement.clientHeight /  2 || document.body.clientHeight /  2
    // this.getTaboolaAd()


    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  methods: {
    getDetail( status ) {
      let language =  navigator.language || navigator.userLanguage || "en-US";//常规浏览器语言和IE浏览器
      let cty = language.split("-")[1]  //获取地区 如US
      let lang = language.split("-")[0]  //获取语言 如en

      let _url 
      if( status == "error" ){
        _url = "https://innews.infohubnews.app/v1/news/en/" + this._id 
      }else {
        _url = "https://innews.infohubnews.app/v1/news/"+ lang  +"/" + this._id 
      }




      //https://innews.infohubnews.app/v1/news/en/cmsbm4boupgbq 
      this.$axios({
        method: 'get',
        url:  _url
      }).then(response => {
        if( response.data.Code == -1  ){
          this.getDetail( "error" )
          return 
        }

        console.log(response, 'success');// 成功的返回
        if (response.status == 200) {
            this.v_html = response.data.Result.content;
            this.title = response.data.Result.title;
            this.author = response.data.Result.author;
            this.link = response.data.Result.link;

            this.getNewsByType(  this._type )
        } else {
          this.error = true;
        }
        this.loading = false;
      }).catch(error => console.log(error, 'error')); // 失败的返回值
    },
    readMore(){
      window.location.href = this.link
    } ,
    getNewsByType( type ){
      // 判断国家  IN, TH, VN, ID, PH   
      let language =  navigator.language || navigator.userLanguage || "en-US";//常规浏览器语言和IE浏览器
      let cty = language.split("-")[1]  //获取地区 如US
      let lang = language.split("-")[0]  //获取语言 如en



      const self = this
      // https://innews.infohubnews.app/v1/channels/news?ch=news669&cty=US&lang=en&ts=10&offset=1&limit=3
      // const apiURL = 'https://innews.infohubnews.app/v1/channels/news?ch=news669&lang=en&cty=US&c=' + type;
      let apiURL ;
      // if( type == "business" ) {
      //   apiURL = 'https://innews.infohubnews.app/v1/channels/news?ch=news669&lang='+ lang +'&cty='+ cty +'&c=' + type;
      // } else {
      //   apiURL = 'https://innews.infohubnews.app/v1/seq-content-news?lang='+ lang +'&cty=' +  cty + '&c=' + type;
      // }
      if( type == 'ainews' ){
        type = 'headline'
      }
      apiURL = `https://innews.infohubnews.app/ad/mixnews?cty=${ cty }&lang=${ lang }&session=${ self.session }&platform=web&c=${ type  }&cc=testnews&offset=${ self.news.offset }&limit=${ self.news.limit }&ts=${ self.time }`


      this.$axios({
        method: 'get',
        url: apiURL 
      }).then(response => {
        // console.log(response, 'success');// 成功的返回
        // self.news.limit = 10
        if (response.status === 200) {
          if (response.data.ads && response.data.ads.length > 0) {
            this.session = response.data.session ? response.data.session : 'init'

            // this.news.offset += 10
            for (let i in response.data.ads) {
              //self.newsList.push(response.data.Result[i]);
              //console.info(response.data.Result[i]);
               if(response.data.ads[i]._id  ){  /*暂时处理*/
                  self.newsList.push(response.data.ads[i]);
               }
            }
          } else {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
        this.loading = false;
      }).catch(error => console.log(error, 'error')); // 失败的返回值
    },
    newsLink (item, id, odd ) {  
      this.$router.replace({ 
          path:'/detail',
          query: {
            id: item._id ,
            type : this._type ,
          }
      })

      this._id = item._id  
      this.newsList = []
      this.getDetail();
      window.scrollTo(0 , 0 )


      let self = this  
      setTimeout(function(){
        if( item._id && !item.channel_id   ){
          //去中间页
          self.$router.replace({ 
              path:'/detail',
              query: {
                id: item._id   , 
                category : self.category, 
                locale : self.locale , 
                language : item.language , 
                cc :  self.cc ,  
                c : self.c ,
                type : this._type ,           
              }
          })
        } else if( item._id && ( item.channel_id &&  item.channel_id != "afnews"  ) ){
          self.$router.replace({ 
              path:'/detail',
              query: {
                id: item._id   , 
                category : self.category, 
                locale : self.locale , 
                language : item.language , 
                cc :  self.cc ,  
                c : self.c ,
                type : this._type ,
      
              }
          })
        } else {
          window.location.href = item.link;
        }
        
      },300)


    },
    watchScroll() {
      // console.log("scrollTop=>", scrollTop, "  offsetTop=>", offsetTop);
    },
    popup_close_click(){ //关闭悬浮框
      this.taboola_popup_show = false ; 
    },
    refresh_time(){
    },


  },
};
</script>


<style  lang="less" scoped>
.title {
  font-weight: 900;
  margin-top: 12px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
  color: #333;
  letter-spacing: 1px;
  word-break: normal;
}
.ad_cont {
  font-size: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid lightgray;
}
.ad_cont .ad_line {
  display: flex;
}
.ad_cont .ad_title {
  font-size: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}
.ad_cont .ad_title .line_2{
  margin-top: 10px;
}
.ad_title_line {
  font-size: 10px;
  box-sizing: border-box;
  padding: 0 5px;
}

.ad_title_line   a{
  text-decoration:none;
  color:#333;
}



.list-title {
  width: 92%;
  margin: 0 auto;
  box-sizing: border-box;
}
.list-title .l {
  font-size: 0.7rem ;
  font-family: Helvetica-Bold, Helvetica;
  height: 1rem;
  line-height: 1rem;
  // font-weight: bold;
  color: #252525;
}
.list-title .r {
  text-align: right;
  height: 1rem;
  line-height: 1rem;
}
.list-item{
  width:92%;
  margin: 10px auto 0;
  min-height: 2.5rem;
  border-bottom: 1px solid #eee;
  font-size: 0.5rem;
  span{
    font-family: PingFangSC-Regular;
    line-height: 1rem;
  }
}

.pop_cont i {
  color: #252525
}


</style>